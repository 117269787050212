import { Button, type ButtonProps } from "@mui/material";
import { NetworkingState } from "src/types/NetworkingState";

export default function SubmitDoneButton<T>({
  title = "Submit",
  isFormValid,
  networkingState,
  onClickDone,
  sx,
  ...rest
}: ButtonProps & {
  title?: string;
  isFormValid: boolean;
  networkingState: NetworkingState<T>;
  onClickDone: () => void;
}) {
  const buttonProps: ButtonProps = {
    fullWidth: true,
    sx: {
      height: "100%",
      ...sx,
    },
    ...rest,
  };

  switch (networkingState.status) {
    case "succeeded":
      return (
        <Button onClick={onClickDone} {...buttonProps} color="primary">
          Done
        </Button>
      );
    default:
      return (
        <Button
          type="submit"
          loading={networkingState.status === "loading"}
          loadingPosition="center"
          disabled={!isFormValid || networkingState.status === "loading"}
          variant="contained"
          color="primary"
          {...buttonProps}
        >
          {networkingState.status !== "loading" ? title : null}
        </Button>
      );
  }
}
