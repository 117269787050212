import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type {
  OrganizationUser,
  OrganizationUserCreate,
  OrganizationUserUpdate,
  PaginatedData,
} from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import { NetworkingState } from "src/types/NetworkingState";
import type { RootState } from "../stores/store";

export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async () => {
    const { user } = await api.organizations.getCurrentUser();
    return user;
  },
);

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async ({ page = 1 }: { page?: number }) => {
    // Get the current page and refresh the data
    const res = await api.organizations.getCurrentUsers(page);
    return res;
  },
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (
    localUser: Omit<OrganizationUserCreate, "organization_id">,
    { getState, dispatch },
  ) => {
    // Create the users.
    const { user } = await api.organizations.createUser(localUser);
    const { user: userState } = getState() as RootState;

    // Trigger refresh of users.
    dispatch(
      getUsers({
        page:
          userState.usersState.status === "succeeded"
            ? userState.usersState.data.page
            : undefined,
      }),
    );
    return user;
  },
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (
    {
      userId,
      updates,
    }: {
      userId: string;
      updates: OrganizationUserUpdate;
    },
    { getState, dispatch },
  ) => {
    await api.organizations.updateUser(userId, updates);

    const { user: userState } = getState() as RootState;

    // Trigger refresh of users.
    dispatch(
      getUsers({
        page:
          userState.usersState.status === "succeeded"
            ? userState.usersState.data.page
            : undefined,
      }),
    );
  },
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ userId }: { userId: string }, { getState, dispatch }) => {
    await api.organizations.deleteUser(userId);

    const { user: userState } = getState() as RootState;

    // Trigger refresh of users.
    dispatch(
      getUsers({
        page:
          userState.usersState.status === "succeeded"
            ? userState.usersState.data.page
            : undefined,
      }),
    );
  },
);

interface UserState {
  currentUserState: NetworkingState<OrganizationUser>;
  usersState: NetworkingState<PaginatedData<OrganizationUser>>;
  userStateById: Record<string, NetworkingState<OrganizationUser> | undefined>;
  createUserState: NetworkingState<OrganizationUser>;
  updateUserState: NetworkingState<undefined>;
  deleteUserState: NetworkingState<undefined>;
}

// Define the initial state using that type
const initialState: UserState = {
  currentUserState: NetworkingState.idle(),
  usersState: NetworkingState.idle(),
  userStateById: {},
  createUserState: NetworkingState.idle(),
  updateUserState: NetworkingState.idle(),
  deleteUserState: NetworkingState.idle(),
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: () => initialState,
    resetCreateUserState: (state) => {
      state.createUserState = initialState.createUserState;
    },
    resetUpdateUserState: (state) => {
      state.updateUserState = initialState.updateUserState;
    },
    resetDeleteUserState: (state) => {
      state.deleteUserState = initialState.deleteUserState;
    },
  },
  extraReducers: (builder) => {
    // GET CURRENT USER
    builder.addCase(getCurrentUser.pending, (state) => {
      state.currentUserState = NetworkingState.loading();
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUserState = NetworkingState.succeeded(action.payload);
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.currentUserState = NetworkingState.failed(action.error.message);
    });

    // GET ALL USERS
    builder.addCase(getUsers.pending, (state) => {
      state.usersState = NetworkingState.loading();
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.usersState = NetworkingState.succeeded(action.payload);
      state.userStateById = action.payload.data.reduce(
        (acc: UserState["userStateById"], user) => {
          acc[user._id] = NetworkingState.succeeded(user);
          return acc;
        },
        {},
      );
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.usersState = NetworkingState.failed(action.error.message);
    });

    // CREATE USER
    builder.addCase(createUser.pending, (state) => {
      state.createUserState = NetworkingState.loading();
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.createUserState = NetworkingState.succeeded(action.payload);
      state.userStateById[action.payload._id] = NetworkingState.succeeded(
        action.payload,
      );
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.createUserState = NetworkingState.failed(action.error.message);
    });

    // UPDATE USER
    builder.addCase(updateUser.pending, (state) => {
      state.updateUserState = NetworkingState.loading();
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.updateUserState = NetworkingState.succeeded(undefined);
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.updateUserState = NetworkingState.failed(action.error.message);
    });

    // DELETE USER
    builder.addCase(deleteUser.pending, (state) => {
      state.deleteUserState = NetworkingState.loading();
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      delete state.userStateById[action.meta.arg.userId];
      state.deleteUserState = NetworkingState.succeeded(undefined);
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.deleteUserState = NetworkingState.failed(action.error.message);
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetUser,
  resetCreateUserState,
  resetUpdateUserState,
  resetDeleteUserState,
} = userSlice.actions;

export default userSlice.reducer;
