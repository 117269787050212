import { updateApiOptions } from "@trainwell/features";
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signOut,
} from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";
import { APP_ROUTES } from "src/constants/AppRoutes";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import "src/lib/firebase";
import { api } from "src/lib/trainwellApi";
import { refreshAppData, resetAppData } from "src/redux/coordinator";

export type AuthRole = "admin" | "member";

interface AuthContextType {
  isAuthenticated: boolean;
  loading: boolean;
  role: AuthRole;
}

const weakAuthRoutes = ["sign-in", "finish-sign-in"];

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUserState = useAppSelector(
    (state) => state.user.currentUserState,
  );

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [role, setRole] = useState<AuthRole>("member");
  const [loading, setLoading] = useState(true);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribeIdTokenChanged = onIdTokenChanged(auth, async (user) => {
      if (!user) return;
      const token = await user.getIdToken(true);
      handleNewToken(token);
    });

    const unsubscribeAuthChange = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        setLoading(false);
        setIsAuthenticated(false);
        redirectToSignInIfNeeded();
        return;
      }

      try {
        const result = await user.getIdTokenResult(true);

        const fetchedUser =
          currentUserState.status === "succeeded"
            ? currentUserState.data
            : undefined;

        const skipLinkUser = user.email === fetchedUser?.email;

        const organizationUserId = result.claims.organizationUserId as
          | string
          | object
          | undefined;

        const role: AuthRole =
          (result.claims.organizationRole as AuthRole | undefined) || "member";

        if (typeof organizationUserId === "string") {
          await dispatch(refreshAppData());
        } else if (user.email && !skipLinkUser) {
          await handleLinkAndFetchUser(user.email);
        }

        setRole(role);
        setIsAuthenticated(true);
      } catch (error) {
        handleSignOut();
      } finally {
        setLoading(false);
      }
    });

    return () => {
      unsubscribeIdTokenChanged();
      unsubscribeAuthChange();
    };
  }, []);

  const handleFetchAuthenticatedUser = async () => {
    await dispatch(refreshAppData());
  };

  const handleLinkAndFetchUser = async (email: string) => {
    await api.organizations.attemptFirebaseSetup(email);
    await handleRefreshToken();
    await handleFetchAuthenticatedUser();
    navigate(APP_ROUTES.HOME.path());
  };

  const redirectToSignInIfNeeded = () => {
    if (weakAuthRoutes.some((path) => location.pathname.includes(path))) {
      return;
    }
    navigate(APP_ROUTES.SIGN_IN.path(), { replace: true });
  };

  const handleRefreshToken = async () => {
    const token = await auth.currentUser?.getIdToken(true);
    handleNewToken(token);
  };

  const handleSignOut = async () => {
    try {
      await dispatch(resetAppData());
      await signOut(auth);
    } catch (error) {
      /* empty */
    } finally {
      setIsAuthenticated(false);
      redirectToSignInIfNeeded();
    }
  };

  const handleNewToken = (token = "") => {
    updateApiOptions({
      prefixUrl: TRAINWELL_API_DOMAIN,
      headers: {
        Authorization: `Bearer ${token}`,
        "api-key": TRAINWELL_API_KEY,
      },
    });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, role }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
